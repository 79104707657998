<template>
  <div>
    <v-card :loading="loading" class="mx-auto my-12" max-width="40%">
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>

      <v-col cols="12" v-if="alert == false">
        <v-alert type="error">
          {{ this.informText }}
        </v-alert>
      </v-col>
      <v-col cols="12" v-if="alert == true">
        <v-alert type="success">
          {{ this.informText }}
        </v-alert>
      </v-col>

      <v-card-title>CHANGE PASSWORD</v-card-title>
      <v-card-subtitle
        ><b>Account: </b>{{ $store.state.gbaUser }}</v-card-subtitle
      >

      <v-divider class="mx-4"></v-divider>

      <v-card-text>
        <v-text-field
          type="password"
          dense
          outlined
          v-model="currentPass"
          hide-details
          label="Curent Password"
        ></v-text-field>
      </v-card-text>

      <v-card-text>
        <v-text-field
          type="password"
          dense
          outlined
          v-model="newPass"
          hide-details
          label="New Password"
        ></v-text-field>
      </v-card-text>

      <v-card-text>
        <v-text-field
          type="password"
          dense
          outlined
          v-model="reEnterPass"
          hide-details
          label="Re-Enter Password"
        ></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="success"
          style="margin-top: 5px"
          @click="changeCurrentUserPassword"
          >Accept</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Amplify, { Auth } from "aws-amplify";

Amplify.configure({
  Auth: {
    region: "ap-southeast-1",
    userPoolId: "ap-southeast-1_8vBdk5PAd",
    userPoolWebClientId: "51e572vhkn38k7tuvsdst697b1",
    mandatorySignIn: true,
  },
});

export default {
  name: "ChangePassword",

  data: () => ({
    currentPass: "",
    newPass: "",
    reEnterPass: "",
    informText: "",
    alert: null,
    loading: false,
  }),

  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    async changeCurrentUserPassword() {
      this.loading = true;
      if (this.newPass == this.reEnterPass) {
        try {
          const user = await Auth.currentAuthenticatedUser({
            bypassCache: false,
          });

          const changePasswordResult = await Auth.changePassword(
            user,
            this.currentPass,
            this.newPass
          );

          if (changePasswordResult == "SUCCESS") {
            this.alert = true;
            this.informText = "Change password successful!";
            this.loading = false;
            await this.sleep(8000);
            this.alert = null;
            this.informText = "";
          }
        } catch (error) {
          console.log("changeCurrentUserPassword: ", error);
          this.alert = false;
          this.informText = error.message;
          this.loading = false;
          await this.sleep(8000);
          this.alert = null;
          this.informText = "";
          //console.log('this.informText: ',this.informText)
        }
      } else {
        this.loading = false;
        this.alert = false;
        this.informText =
          "Not matching password between New Password and Re-Enter Password!";
      }
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
</style>