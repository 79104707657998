<template>
  <changePassword />
</template>

<script>
// @ is an alias to /src
import changePassword from "@/components/Profile/c-profile-change-pass.vue";

export default {
  name: "ChangePassword",
  components: {
    changePassword,
  },
};
</script>
